import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {AsyncDataCache} from '@util/classes/async-data-cache.class';
import {getUrlOfEndpoint} from '@util/functions/strings';
import {Observable, of} from 'rxjs';
import {catchError, filter, skip} from 'rxjs/operators';
import {KurzLanguageService} from './kurz-language.service';

export type MailType = 'PRICE' | 'ORDER_DETAIL' | 'CONTRACT' | 'NEW_CONTRACT' | 'PRODUCT_SAMPLE' | 'PRODUCT_REQUEST' | 'REQUEST_ACCESS' | 'FOIL_CONFIGURATION_REQUEST' | 'GENERAL_REQUEST';

export interface KurzMailOptionObject {
  /**
   * the related contract code
   */
  contractId?: string;
  /**
   * the customer's message
   */
  customerInput?: string;
  /**
   * the enum to recognize the mail type
   */
  mailType: MailType;
  /**
   * the related order code
   */
  orderId?: string;
  /**
   * the related product code or materialNumber
   */
  productId?: string;
  /**
   * required if mailType === 'REQUEST_ACCESS' or anonymous user 'PRODUCT_SAMPLE' or 'PRODUCT_REQUEST'
   */
  name?: string;
  email?: string;
  companyName?: string;
  streetAndNumber?: string;
  postcodeAndCity?: string;
  /**
   * foilConfig data, required if mailType ===
   */
  core?: string;
  length?: number;
  width?: number;
  finishingType?: string;
  /**
   * the code of CountryMailAddress;
   * needs to be sent if the user is anonymous / not logged in
   */
  countryMail?: CountryMailAddress['code'];
}

export interface CountryMailAddress {
  code: string;
  name: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class KurzMailService {

  private httpClient = inject(HttpClient);
  private kurzLanguageService = inject(KurzLanguageService);

  private kurzCountryMailAddressesAsyncDataCache = new AsyncDataCache(
    '_kurzCountryMailAddresses',
    {
      dataSerializable: false,
      // eslint-disable-next-line no-magic-numbers
      maxAge: (1000 * 60 * 60 * 4),
      sources: [
        {
          key: 'kurzCountryMailAddresses',
          object: {
            source: () => (this.requestCountryMailAddresses().pipe(filter(data => !!data))),
            refreshWhen: this.kurzLanguageService.getActive().pipe(skip(1))
          }
        }
      ]
    }
  );

  sendMail(obj: KurzMailOptionObject): Observable<{ response: string; }> {

    let url: string;
    if (obj.mailType === 'REQUEST_ACCESS' || obj.mailType === 'GENERAL_REQUEST') {
      url = getUrlOfEndpoint('/anonymous/mail/send');
    } else {
      url = getUrlOfEndpoint('/mail/send');
    }

    return this.httpClient.post<{ response: string; }>(url, obj).pipe(
      catchError((err, caught) => {
        console.warn('catchError', err, caught);
        const errorResponse = { response: 'error' };
        return of(errorResponse);
      }
      ));

  }

  getCountryMailAddresses(): Observable<CountryMailAddress[]> {
    return this.kurzCountryMailAddressesAsyncDataCache.getResponse();
  }

  private requestCountryMailAddresses(): Observable<CountryMailAddress[]> {

    const endpoint = '/countryMails';
    const url = getUrlOfEndpoint(endpoint);

    return this.httpClient.get<CountryMailAddress[]>(url).pipe(
      catchError(err => {
        console.error('error while requesting country mail addresses', err);
        return of([]);
      })
    );

  }

}

